* {
    scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
    scrollbar-width: thin;
}

body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        'Courier New',
        monospace;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 6px;
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid white;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 6px;
    cursor: pointer;
}

/* Стрелки */
::-webkit-scrollbar-button {
    height: 0;
}

.no-scrollbar {
    scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.spin-right-margin {
    margin-right: 8px;
}

.ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
}
